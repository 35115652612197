import React from 'react';
import { navigate } from 'gatsby';
import prev from '../../images/icons/prev.svg';
import next from '../../images/icons/next.svg';

const Pagination = ({ currentPage, numPages, prefix }) => {
  const isFirst = currentPage <= 1;
  const isLast = currentPage === numPages;
  const prevPagePath = currentPage - 1 <= 1 ? prefix : `${prefix}${(currentPage - 1).toString()}`;
  const nextPagePath =
    currentPage + 1 <= numPages
      ? `${prefix}${(currentPage + 1).toString()}`
      : `${prefix}${currentPage.toString()}`;

  return (
    <div className="flex justify-center w-full gap-2">
      <button
        className={`flex justify-center items-center bg-brand-blue-5 rounded-full w-10 h-10 transition-all ${
          isFirst ? 'disabled:opacity-75 disabled:cursor-not-allowed' : ''
        }`}
        disabled={isFirst}
        onClick={() => navigate(prevPagePath)}
      >
        <img src={prev} height={12} width={12} alt="previous" />
      </button>

      {Array.from({ length: numPages }, (_, index) => (
        <button
          key={'pagination' + (index + 1)}
          className={`flex justify-center items-center text-xs font-bold hover:text-brand-blue-5 hover:bg-white rounded-full w-10 h-10 transition-all ${
            currentPage === index + 1
              ? 'text-brand-blue-5 border bg-white'
              : 'text-white bg-brand-blue-5'
          }`}
          onClick={() => navigate(`${index === 0 ? prefix : prefix + (index + 1)}`)}
        >
          {index + 1}
        </button>
      ))}

      <button
        className={`flex justify-center items-center bg-brand-blue-5 rounded-full w-10 h-10 transition-all ${
          isLast ? 'disabled:opacity-75 disabled:cursor-not-allowed' : ''
        }`}
        disabled={isLast}
        onClick={() => navigate(nextPagePath)}
      >
        <img src={next} height={12} width={12} alt="next" />
      </button>
    </div>
  );
};

export default Pagination;
