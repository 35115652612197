import React, { useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SmallerBlueArrow } from '../components/layout/arrows';
import Pagination from '../components/layout/pagination';
import Title from '../components/layout/title';

const ListingCardsTemplate = ({ data, pageContext }) => {
  const paginationRef = useRef();
  const opengraph = getImage(data?.opengraph?.childImageSharp) || null;
  const {
    title,
    subtitle,
    description,
    currentPage,
    numPages,
    prefix,
    featuredItem,
    filters,
    skip,
    list,
    listingType,
  } = pageContext;
  // Featured card from markdown or static array
  const slug = featuredItem?.slug ? `/${featuredItem.slug}` : featuredItem.href;
  const summary = featuredItem?.summary || featuredItem.description;
  const image = featuredItem?.frontmatter?.image || data?.featuredImage;
  const image_alt = featuredItem?.frontmatter?.image_alt || featuredItem.title;
  const featuredTitle = featuredItem?.frontmatter?.title || featuredItem.title;
  const date_modified = featuredItem?.frontmatter?.date_modified;

  const listArr = data?.allMdx?.nodes.length > 0 ? data?.allMdx?.nodes : data?.staticArr?.nodes;
  const imagesArr = data?.imagesArr;

  useEffect(() => {
    if (typeof window !== 'undefined' && currentPage > 1) {
      window?.scrollTo({
        behavior: 'smooth',
        top: paginationRef?.current?.getBoundingClientRect().top + window?.scrollY,
      });
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={title || ''}
        description={description || ''}
        pathname={typeof window !== 'undefined' && location ? location.pathname : ''}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      <div className="bg-white overflow-x-hidden">
        <div className="relative max-w-7xl mx-auto px-5 xl:px-8">
          <section>
            <Title title={title || ''} border={false}>
              <p
                className="text-left text-sm font-light mb-4 text-gray-700 w-full max-w-3xl sm:text-xl sm:mb-5"
                dangerouslySetInnerHTML={{ __html: subtitle || '' }}
              />
            </Title>
          </section>
          {featuredItem && (
            <section>
              <div className="p-2 -mx-2 mb-5 border-brand-gray-2 hover:border-white focus:border-white hover:border-1 focus:border-1 hover:ring-2 focus:ring-2 hover:ring-blue-300 focus:ring-blue-300 hover:rounded-md focus:rounded-md xl:p-6 xl:-mx-6 xl:my-6 sm:gap-0">
                <Link
                  to={slug}
                  className="flex flex-col gap-4 pb-4 bg-brand-blue-5 rounded-md overflow-hidden isolate sm:flex-row sm:bg-transparent sm:pb-0"
                >
                  <GatsbyImage
                    image={image ? getImage(image.childImageSharp) : opengraph ? opengraph : ''}
                    className="w-full object-contain shadow-lg max-h-72 rounded-none overflow-hidden isolate mb-4 mr-0 sm:w-1/2 sm:mr-12 sm:mb-0 sm:rounded-md"
                    loading="eager"
                    alt={image_alt || 'listing_opengraph'}
                  />
                  <div className="flex flex-col my-auto w-full px-4 gap-2 sm:w-1/2 sm:px-0">
                    <span className="text-xs font-bold uppercase text-white sm:text-brand-blue-4">
                      Featured
                    </span>
                    <h3 className="text-left text-base font-bold capitalize text-brand-green-3 pt-2 sm:text-lg sm:text-brand-blue-5 md:text-2xl lg:text-3xl sm:font-black sm:pt-0">
                      {featuredTitle}
                    </h3>
                    <span className="text-left text-xs italic font-light text-brand-blue-1 sm:text-gray-700">
                      {date_modified}
                    </span>
                    <span className="text-sm font-light text-brand-blue-1 sm:text-gray-700 sm:text-lg">
                      {summary}
                    </span>
                  </div>
                </Link>
              </div>
            </section>
          )}
        </div>
        <div className="w-full bg-brand-blue-1">
          <section>
            <div ref={paginationRef} className="flex flex-col gap-7 py-4 sm:gap-12 sm:py-12">
              {filters && (
                <div className="flex w-max mx-auto bg-white border border-brand-blue-4 rounded-full p-2.5">
                  {filters.map((filter, index) => (
                    <Link
                      to={filter.path}
                      key={`filter_${index}`}
                      className={`text-xs font-bold sm:text-base rounded-full py-2.5 px-5 capitalize ${
                        listingType === filter.type
                          ? 'text-white bg-brand-blue-4'
                          : 'text-black bg-transparent'
                      }`}
                    >
                      {filter.title}
                    </Link>
                  ))}
                </div>
              )}
              <div className="grid justify-items-stretch grid-flow-row grid-cols-1 gap-4 pb-4 items-start relative max-w-7xl mx-auto px-5 sm:pb-6 sm:grid-cols-2 xl:grid-cols-3 xl:px-8 sm:gap-12">
                {listArr?.map((node, index) => {
                  // posts list from markdown or static array
                  const slug = node.slug ? `/${node.slug}` : list[index + skip].href;
                  const summary = node.summary || list[index + skip].description;
                  const image =
                    node.frontmatter?.image ||
                    imagesArr?.nodes?.find((ele) => ele.relativePath === list[index + skip].image);
                  const image_alt = node.frontmatter?.image_alt || list[index + skip].title;
                  const featuredTitle = node.frontmatter?.title || list[index + skip].title;
                  const date_modified = node.frontmatter?.date_modified;
                  return (
                    <Link
                      key={`ListingCardsTemplate${prefix + (index + skip)}`}
                      to={slug}
                      className="flex flex-col justify-self-stretch outline-none border-none justify-start gap-4 ring-offset-8 ring-offset-brand-blue-1 overflow-hidden isolate hover:ring-2 focus:ring-2 hover:ring-blue-300 focus:ring-blue-300 hover:rounded-md focus:rounded-md sm:max-w-[347px]"
                    >
                      <GatsbyImage
                        className="w-full object-contain shadow-lg overflow-hidden isolate rounded-t-md rounded-b-md sm:max-w-[346px] sm:rounded-b-none"
                        image={image ? getImage(image.childImageSharp) : opengraph ? opengraph : ''}
                        loading="lazy"
                        alt={image_alt || 'listing_opengraph'}
                      />
                      <div className="flex flex-col w-full gap-1 pb-4 px-0 sm:px-4 sm:pb-0">
                        <h2 className="text-left text-base font-bold capitalize sm:text-base text-brand-blue-5">
                          {featuredTitle}
                        </h2>
                        <span className="text-left text-xs italic font-light text-gray-700">
                          {date_modified}
                        </span>
                        <span className="line-clamp-4 text-sm font-light text-gray-700 sm:line-clamp-3">
                          {summary}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <Pagination currentPage={currentPage} numPages={numPages} prefix={prefix} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $skip: Int!
    $limit: Int!
    $paths: [String]!
    $listingType: String!
    $opengraph: String!
    $imagesArr: [String]!
    $staticArr: [String]!
    $featuredImage: String!
  ) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: $listingType } }, slug: { in: $paths } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          date_modified
          title
          election_name
          image: featured_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt: featured_image_alt
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    opengraph: file(relativePath: { eq: $opengraph }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    featuredImage: file(relativePath: { eq: $featuredImage }) {
      relativePath
      childImageSharp {
        gatsbyImageData
      }
    }
    imagesArr: allFile(filter: { relativePath: { in: $imagesArr } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    staticArr: allFile(filter: { relativePath: { in: $staticArr } }, limit: $limit, skip: $skip) {
      nodes {
        relativePath
      }
    }
  }
`;

export default ListingCardsTemplate;
