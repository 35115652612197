import React from 'react';
import { SmallerBlueArrow } from './arrows';

const Title = ({ title, children, border = true }) => (
  <>
    <div
      className={`text-left mt-6 sm:mt-16 pb-6 sm:pb-8 pt-4 sm:pt-0 ${
        border ? 'border-b border-slate-300 mb-6 sm:mb-12' : ''
      }`}
    >
      <SmallerBlueArrow>
        <h1 className="inline pt-4 sm:pt-0 pr-8 text-3xl sm:text-4xl lg:text-6xl font-bold sm:font-black text-gray-900 tracking-tight mb-4">
          {title}
        </h1>
      </SmallerBlueArrow>
      <p className="mt-3 text-xl text-gray-700 leading-relaxed sm:w-2/3">{children}</p>
    </div>
  </>
);

export default Title;
